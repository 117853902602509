// Import Libraries
import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import MainModalBox from './modalMimicPage/mainModalBox';
import MainModalFooter from './modalMimicPage/mainModalFooter';
import Icon from '../../components/Elements/Icon.js';

// Get Context
import SessionContext from '../../context/Session';

// Build Component
const ModalMimicPage = ({ page, footer, back, close, width }) => {
  const { isMobile } = useContext(SessionContext);

  function goBack() {
    if (back === true) {
      window.history.back();
    } else if (back) {
      window.location.href = `${window.location.origin}${back}`;
    }
  }

  function handleClose() {
    if (close === true) {
      window.history.back();
    } else if (close) {
      window.location.href = `${window.location.origin}${close}`;
    }
  }

  const BackButton = () => {
    return (
      <>
        {back ? (
          <Icon
            icon="LeftArrow"
            size={18}
            color="Black"
            customStyle={{
              alignContent: 'end',
              backgroundColor: '#ffffff',
              marginTop: '-5px',
              borderRadius: '20px',
              border: '1px solid #D9DBDD',
              marginLeft: '10px',
            }}
            onClick={() => goBack()}
          />
        ) : (
          <div
            style={{
              padding: '5px 13px',
              width: '60px',
              marginLeft: '10px',
            }}
          >{` `}</div>
        )}
      </>
    );
  };

  const CloseButton = () => {
    return (
      <>
        {close ? (
          <Icon
            icon="Close"
            size={18}
            color="Black"
            customStyle={{
              alignContent: 'end',
              backgroundColor: '#ffffff',
              marginTop: '-5px',
              borderRadius: '20px',
              border: '1px solid #D9DBDD',
              float: 'right',
              marginRight: '30px',
            }}
            onClick={() => handleClose()}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <>
          <div>
            <div
              className="fullWidthContainer"
              style={{ paddingBottom: '0px', minHeight: '40px', alignContent: 'end' }}
            >
              <BackButton />
              <CloseButton />
            </div>
            <div className="DeepPadded">{page ? page : null}</div>
            <div className="modalMimicPageModalFooter">{footer ? footer : null}</div>
          </div>
        </>
      ) : (
        <div className={`Page modalMimicPage`}>
          <div>
            <div
              className="fullWidthContainer Padded"
              style={{ zIndex: '2', position: 'relative' }}
            >
              <BackButton />
              <CloseButton />
            </div>
            <div
              className="fullWidthContainer Padded"
              style={{ alignItems: 'stretch', marginTop: '-40px', zIndex: '1 !important' }}
            >
              <div className="OnePortionFlex Padded"> </div>
              <div
                className="FivePortionFlex Padded"
                style={{
                  textAlign: '-webkit-center',
                  maxWidth: width === '800' ? '800px' : '640px',
                }}
              >
                <MainModalBox page={page} />
                <MainModalFooter footer={footer} />
              </div>
              <div className="OnePortionFlex Padded"> </div>
            </div>
          </div>
          <div className="fullWidthContainer"></div>
        </div>
      )}
    </>
  );
};

export default ModalMimicPage;
