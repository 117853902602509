// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { FullStory } from '@fullstory/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClose, faBars } from '@fortawesome/free-solid-svg-icons';

// Context
import { UserContext } from '../App';

// Components
import Login from './UserComponents/login.js';
import Logout from './UserComponents/logout.js';
import AdminMenu from '../admin/components/AdminHeader.js';
import Icon from './Elements/Icon.js';

//Services
import UserService from '../services/userService';

Modal.setAppElement('#root');

// Construct Component
const Header = ({ isStaff }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isMainDropdownOpen, setIsMainDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isAuthenticated, isLoading } = useAuth0();
  const [isMobile, setIsMobile] = useState(false);
  const site = window.location.origin;

  useEffect(() => {
    let page = window.location.pathname;
    if (site.indexOf('demo') === -1 && site.indexOf('localhost') === -1) {
      if (page === '/') {
        window.location.replace(`https://www.shotkraft.com${page}`);
      }
      if (page === '/contactUs' || page === '/faq') {
        window.open(`${site}${page}`, '_blank');
      }
    }

    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
        setIsMainDropdownOpen(false);
        setIsDropdownOpen(false);
      } else {
        setIsMobile(false);
        setIsMainDropdownOpen(false);
        setIsDropdownOpen(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoading]);

  const clickLogo = () => {
    if (user && user.id) {
      window.location.replace(`${site}/app/home`);
    } else {
      window.location.replace(site);
    }
  };

  const handleScriptUpload = () => {
    UserService.addActionLog('New Project ', null, null, 'From Header');
    FullStory('trackEvent', {
      name: 'InitiateNewScriptHeader',
    });

    window.location.replace(`${site}/app/newProject/1`);
  };

  return (
    <>
      <header className="FullWidth header">
        <img
          src="/Logo2White.png"
          style={{ cursor: 'pointer' }}
          className="logo"
          alt="logo"
          onClick={() => clickLogo()}
        />
        <span style={{ flex: 'auto' }}></span>

        {isAuthenticated ? (
          <FontAwesomeIcon
            icon={faBars}
            className="header_nav_icon barIcon"
            onClick={() => setIsMainDropdownOpen(!isMainDropdownOpen)}
          />
        ) : (
          <Login useClass="headerItem signupButton" />
        )}
        <nav className={`header_navigation ${isMainDropdownOpen ? 'open' : ''}`}>
          {isAuthenticated ? (
            <>
              <span className="headerItem mobileOnly">
                <FontAwesomeIcon
                  icon={faClose}
                  className="linkText"
                  style={{ float: 'right', color: '#0E0F11', fontSize: '24px', paddingTop: '20px' }}
                  onClick={() => setIsMainDropdownOpen(!isMainDropdownOpen)}
                />
              </span>

              <a href="/app/home" className="headerItem">
                <Icon
                  icon="Home"
                  color="Purple"
                  customClass="header_nav_icon"
                  customStyle={{ marginTop: '-10px' }}
                />
                &nbsp;&nbsp; My Projects
              </a>
              <a onClick={handleScriptUpload} className="headerItem">
                <Icon
                  icon="Add"
                  color="Purple"
                  customClass="header_nav_icon"
                  customStyle={{ marginTop: '-10px' }}
                />
                &nbsp;&nbsp; New Project
              </a>

              <a href="/app/profile" className="headerItem mobileOnly">
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ color: '#7A64EB', marginBottom: '0px' }}
                  className="header_nav_icon"
                />
                &nbsp;&nbsp; My Profile
              </a>

              {(isDropdownOpen || isMainDropdownOpen) && isStaff && isMobile ? <AdminMenu /> : null}

              <span className="headerItem mobileOnly">
                <FontAwesomeIcon icon={faUser} className="linkText userIcon header_nav_icon" />
                &nbsp;&nbsp;
                <Logout useClass="dropdownMenuItem" />
              </span>

              <span
                className="headerItem userIconA nonMobileOnly"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <FontAwesomeIcon icon={faUser} className="linkText userIcon" />
                {isDropdownOpen || isMainDropdownOpen ? (
                  <div className="dropdownMenuItem">
                    {isStaff ? <AdminMenu /> : null}
                    <a className="dropdownItem" href="/app/profile">
                      My Profile
                    </a>
                    <Logout useClass="dropdownItem" />
                  </div>
                ) : null}
              </span>
            </>
          ) : (
            <></>
          )}
        </nav>
      </header>
    </>
  );
};

export default Header;
